<template>
  <div>
    <div class="datasear">
      <el-form ref="form" :model="form" label-width="130px">
        <el-row>
          <!--          <el-col :span="4"
                      ><div class="grid-content bg-purple">
                        <el-form-item label="年度">
                          <el-date-picker
                            v-model="value1"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                          >
                          </el-date-picker>
                        </el-form-item></div
                    ></el-col>-->
          <el-col :span="4">
            <div class="grid-content bg-purple-light">
              <el-form-item label="所属气候区">
                <el-select @change="search" clearable v-model="form.weather" placeholder="所属气候区">
                  <el-option label="夏热冬冷" :value="1"></el-option>
                  <el-option
                      label="寒冷"
                      :value="2"
                  ></el-option> </el-select
                ></el-form-item></div
            ></el-col>
          <el-col :span="4"
          ><div class="grid-content bg-purple">
            <el-form-item label="建筑类型">
              <el-select @change="search" clearable v-model="form.building_type" placeholder="建筑类型">
                <el-option label="公共建筑" :value="1"></el-option>
                <el-option
                    label="居住建筑"
                    :value="2"
                ></el-option> </el-select
              ></el-form-item></div
          ></el-col>
          <el-col :span="4"
          ><div class="grid-content bg-purple-light">
            <el-form-item
                label="建筑功能"
                prop="building_ability"
            >
              <el-select
                  @change="search"
                  clearable
                  v-if="form.building_type == 1"
                  v-model="form.building_ability"
                  placeholder="请选择建筑功能"
              >
                <el-option
                    v-for="item in weatherOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>

              <el-select
                  v-else
                  @change="search"
                  clearable
                  v-model="form.building_ability"
                  placeholder="请选择建筑功能"
              >
                <el-option
                    v-for="item in juzhuOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item></div
          ></el-col>

        </el-row>
        <el-row>
          <el-col :span="4"
          ><div class="grid-content bg-purple">
            <el-form-item label="标识等级">
              <el-select @change="search" clearable v-model="form.after_stars" placeholder="标识阶段">
                <el-option label="一星" :value="1"></el-option>
                <el-option label="二星" :value="2"></el-option>
                <el-option label="三星" :value="3"></el-option>
              </el-select>
            </el-form-item></div
          ></el-col>
          <el-col :span="4"
          ><div class="grid-content bg-purple">
            <el-form-item label="测评单位">
              <el-input @keyup.native.enter="search" v-model="form.test_company"></el-input>
            </el-form-item></div
          ></el-col>


          <!--          <el-col :span="4"
                      ><div class="grid-content bg-purple-light">
                        <el-form-item label="总建筑面积(万m²)">
                          <el-input v-model="form.name" placeholder=""></el-input
                        ></el-form-item></div
                    ></el-col>
                    <el-col :span="4"
                      ><div class="grid-content bg-purple">
                        <el-form-item label="总项目个数">
                          <el-input v-model="form.name" placeholder=""></el-input
                        ></el-form-item></div
                    ></el-col>
                    <el-col :span="4"
                      ><div class="grid-content bg-purple-light">
                        <el-form-item label="统计类型">
                          <el-select v-model="form.region" placeholder="项目数量(个)">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option
                              label="区域二"
                              value="beijing"
                            ></el-option> </el-select
                        ></el-form-item></div
                    ></el-col>-->
        </el-row>
      </el-form>
    </div>
    <div class="datachart">
      <div class="dcleft">
        <div class="dtltitle">年度统计柱形图</div>
        <div class="tuechart">
          <div id="charts-demo" class="echart"></div>
        </div>
      </div>
      <div class="dcright">
        <div class="dtltitle">年度统计扇形图</div>
        <div id="chartstwo" class="echart2"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {dataYearApi} from "../../../../api/project";

export default {
  data() {
    return {
      form: {},
      value1: "",
      projects:[],
      areas: [],

      //公共建筑
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],
      //居住建筑
      juzhuOptions: [
        {
          name: "住宅",
          id: 1,
        },
        {
          name: "基地",
          id: 2,
        },
      ],
    };
  },
  methods: {

    search() {
      //获得项目个数
      this.getData(1);

      //获得面积
      this.getData(2);
    },

    //获得图表数据
    getData(type){
      let params = {
        token: this.$store.state.token,
        type: type
      };
      params = Object.assign(params, this.form);
      let that = this;
      dataYearApi(params).then(res => {
        console.log(res);
        if (type==1){
          that.projects = res.data.data;
          that.$nextTick(function (){
            that.drawLine();
          })
        }else {
          that.areas = res.data.data;
          that.$nextTick(function (){
            that.drawLine2();
          })
        }
      })
    },

    // 柱形图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("charts-demo"));
      let label = [];
      let value = [];
      for(let i = 0; i<this.projects.length;i++){
        label.push(this.projects[i].date);
        value.push(this.projects[i].value);
      }
      // 绘制图表
      myChart.setOption({
         grid: {
          width: "auto",
          height: "auto",
        },
        color: ['#3086fb'],
        xAxis: {
          type: "category",
          data: label,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: value,
            type: "bar",
          },
        ],
      });
    },
    // 饼形图
    drawLine2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("chartstwo"));
      for(let i = 0; i<this.areas.length;i++){
        this.areas[i].name = this.areas[i].date;
      }
      // 绘制图表
      myChart.setOption({
         grid: {
          width: "auto",
          height: "auto",
        },
        title: {
          // text: "Referer of a Website",
          // subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        // },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: "50%",
            data: this.areas,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
  },
  mounted() {
    //获得项目个数
    this.getData(1);

    //获得面积
    this.getData(2);
  },
};
</script>
<style lang="scss" scoped>
.echart2{
   width: 100%;
  height: 620px;
}
.echart {
  width: 100%;
  height: 620px;
}
.datachart {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .dcleft {
    width: 59%;
    background-color: #ffffff;
    padding: 30px;
    margin-right: 20px;
    .dtltitle {
      border-left: 4px solid #3086fb;
      font-size: 24px;
      padding-left: 15px;
      font-weight: 400;
      color: #333333;
    }
  }
  .dcright {
    width: 40%;
    padding: 30px;
    background-color: #ffffff;
    .dtltitle {
      border-left: 4px solid #3086fb;
      font-size: 24px;
      padding-left: 15px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
